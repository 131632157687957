<template>
     <div class="bid_index">
          <div class="bid_index_con">
               <div class="bid_info_card" v-for="(val,key,index) in infoList" :key="index">
                    <div class="header">
                         <span>{{infoMap[key]}}</span>
                    </div>
                    <div class="bid_info flex_row_start_start">
                         <div class="bid_left flex_column_start_center">
                              <div class="cate_btn" :class="{chosen:val.tab==0}" @click="choose(0,key)">招标公告</div>
                              <div class="cate_btn" :class="{chosen:val.tab==1}" @click="choose(1,key)">中标公告</div>
                              <div class="cate_btn" :class="{chosen:val.tab==2}" @click="choose(2,key)">终止公告</div>
                         </div>
                         <div class="bid_right">
                              <div v-if="val.list.length">
                                   <div class="info_line" v-for="(item,index1) in val.list" :key="index1">
                                        <div class="flex_row_between_center" v-if="index1<=5">
                                             <span class="title"
                                                  @click="toDetail(item,val.tab)">{{item.noticeTitle}}</span>
                                             <span class="time">{{item.createTime.split(' ')[0]}}</span>
                                        </div>
                                   </div>
                                   <div class="flex_row_center_center" style="margin:10px 0;">
                                        <div class="see_all" v-if="val.list.length>6" @click="seeMore(index+1,val.tab)">
                                             查看全部 >
                                        </div>
                                   </div>

                              </div>
                              <div class="flex_column_center_center new_empty" v-else>
                                   <img src="@/assets/new_empty.png">
                                   <p>暂无数据</p>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import { ref, getCurrentInstance, onMounted, reactive } from 'vue'
     import { useRouter } from 'vue-router'
     export default {
          setup() {
               const { proxy } = getCurrentInstance()
               const router = useRouter()
               const choose = (tab, key) => {
                    infoList[key].tab = tab
                    judgeType(key, tab)
               }

               const judgeType = (key, noticeType) => {
                    switch (key) {
                         case "goods": {
                              getBidInfo(1, noticeType)
                              break
                         }

                         case "project": {
                              getBidInfo(2, noticeType)
                              break
                         }

                         case "service": {
                              getBidInfo(3, noticeType)
                              break
                         }
                    }
               }

               const infoMap = reactive({
                    goods: '货物招标',
                    project: '工程招标',
                    service: '服务招标'
               })


               const infoList = reactive({
                    goods: { list: [], tab: 0 },
                    project: { list: [], tab: 0 },
                    service: { list: [], tab: 0 },
               })


               const tab = reactive({
                    goodsTab: 0,
                    projectTab: 0,
                    serviceTab: 0
               })

               const getBidInfo = (type, noticeType) => {
                    proxy.$get('v3/business/front/enterBid/list', { type, noticeType }).then(res => {
                         if (res.state == 200) {
                              switch (type) {
                                   case 1: {
                                        infoList.goods.list = res.data.list
                                        break
                                   }

                                   case 2: {
                                        infoList.project.list = res.data.list
                                        break
                                   }

                                   case 3: {
                                        infoList.service.list = res.data.list
                                        break
                                   }
                              }
                         }
                    })
               }

               const toDetail = (item, tab) => {

                    let noteMap = {
                         0: '/bid/detail',
                         1: '/bid/winbidNoti',
                         2: '/bid/terBidNoti'
                    }

                    let query = {
                         bidId: item.bidId
                    }

                    if (item.endId) {
                         query.endId = item.endId
                    }

                    if (item.winId) {
                         query.winId = item.winId

                    }

                    router.push({
                         path: noteMap[tab],
                         query
                    })
               }


               const seeMore = (type, noticeType) => {
                    console.log(type, noticeType)
                    if (noticeType == 2) {
                         router.push({
                              path: '/bid/more_terBid',
                              query: {
                                   type, noticeType
                              }
                         })
                    } else {
                         router.push({
                              path: '/bid/more',
                              query: {
                                   type, noticeType
                              }
                         })
                    }

               }


               onMounted(() => {
                    getBidInfo(1)
                    getBidInfo(2)
                    getBidInfo(3)

               })

               return {
                    choose,
                    infoMap,
                    infoList,
                    toDetail,
                    seeMore
               }

          }
     }
</script>

<style lang="scss">
     .bid_index {
          background: #F5F5F5;
          padding-top: 20px;

          .bid_index_con {
               width: 1200px;
               margin: 0 auto;
          }

          .bid_info_card {
               min-height: 311px;
               background: #FFFFFF;
               border: 1px solid #DCDCDC;
               margin-bottom: 20px;

               .header {
                    height: 50px;
                    background: #F6F8FF;
                    line-height: 50px;
                    padding-left: 20px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
               }

               .bid_info {
                    .bid_left {
                         width: 207px;
                         padding-top: 20px;

                         .cate_btn {
                              height: 38px;
                              background: #FFFFFF;
                              border: 1px solid #A3A3A3;
                              border-radius: 3px;
                              text-align: center;
                              line-height: 38px;
                              font-size: 16px;
                              font-family: Microsoft YaHei;
                              font-weight: 400;
                              color: #666666;
                              width: 100px;
                              margin-bottom: 20px;
                              cursor: pointer;

                              &.chosen {
                                   background: rgba(40, 95, 222, 0.0600);
                                   border: 1px solid #285FDE;
                                   color: #285FDE;
                              }
                         }
                    }

                    .bid_right {
                         padding-top: 20px;
                         padding-right: 20px;
                         flex: 1;

                         .info_line {
                              margin-bottom: 20px;

                              .title {
                                   font-size: 14px;
                                   font-family: Microsoft YaHei;
                                   font-weight: 400;
                                   color: #333333;
                                   cursor: pointer;

                                   &:hover {
                                        text-decoration: underline;
                                        color: #285FDE;
                                   }
                              }

                              .time {
                                   font-size: 12px;
                                   font-family: Microsoft YaHei;
                                   font-weight: 400;
                                   color: #999999;
                              }
                         }

                         .see_all {
                              font-size: 14px;
                              font-family: Microsoft YaHei;
                              font-weight: 400;
                              color: #888888;
                              cursor: pointer;

                              &:hover {
                                   text-decoration: underline;
                              }
                         }
                    }
               }
          }

          .new_empty {
               img {
                    width: 150px;
                    height: 150px;
               }

               p {
                    margin-top: 5px;
                    font-size: 16px;
               }
          }
     }
</style>